<template>
    <div class="box">
        <!-- 导航栏 -->
        <div class="timeTabs">
            <div v-for="(item,index) in timeTabs" :key="index" :class='[num==index ? "active":"",index=="0" ? "borderLeft widtha" : "widthb"]' @click="timeTab(index)" >
                {{item}}
            </div>
        </div>
        <!-- 日期选择器 -->
        <el-date-picker
        :disabled="disabled"
        value-format="yyyy-MM-dd"
        v-model="time"
        type="daterange"
        align="right"
        @change="change"
        unlink-panels
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions">
        </el-date-picker>
    </div>
</template>
<script>
export default {
    data(){
        return{
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e6;  //如果没有后面的-8.64e6就是不可以选择今天的 
                    },
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                    }
                }]
            },
            // 日期选择器的结果
            time:'',
            timeTabs:['全部','昨日','今日','自定义'],
            num:0,
            disabled:true,
            // 今日的日期
            today:'',
            // 昨天的日期
            yesterday:'',
            time_interval:{
                // 开始的时间
                start_time:'',
                // 结束的时间
                end_time:'',
            },
        }
    },
    created(){
        // 今天的日期
        var date = new Date();       
        var mon = date.getMonth() + 1;       
        var day = date.getDate();
        this.today = date.getFullYear() + "-"+ (mon<10?"0"+mon:mon) + "-"+(day<10?"0"+day:day);
        // 昨天的日期
        date.setTime(date.getTime()-24*60*60*1000)
        var mon = date.getMonth() + 1;       
        var day = date.getDate();
        this.yesterday = date.getFullYear() + "-"+ (mon<10?"0"+mon:mon) + "-"+(day<10?"0"+day:day);
    },
    methods:{
        reset() {
            this.num=0
        },
        timeTab(index){
            if(index == this.num) return ;
            if(index==0){
                // this.time=''
                this.time_interval={
                        // 开始的时间
                        start_time:'',
                        // 结束的时间
                        end_time:'',
                    }
                this.num = index;
                this.disabled=true
                this.$emit('selector',this.time_interval)
            }else if(index==1){
                this.time_interval={
                        // 开始的时间
                        start_time:this.yesterday,
                        // 结束的时间
                        end_time:this.yesterday,
                    }
                this.num=index
                this.disabled=true
                this.$emit('selector',this.time_interval)
            }else if(index==2){
                this.time_interval={
                        // 开始的时间
                        start_time:this.today,
                        // 结束的时间
                        end_time:this.today,
                    }
                this.num=index
                this.disabled=true
                this.$emit('selector',this.time_interval)
            }else{
                this.disabled=false
                this.num=index
            }
        },
        change(){
            this.time_interval={
                        // 开始的时间
                        start_time:this.time[0],
                        // 结束的时间
                        end_time:this.time[1],
                    }
            this.$emit('selector',this.time_interval)
            
            // console.log(maxDate)
            // console.log(minDate)
        }
    }
}
</script>
<style lang="less" scoped>
    .box{
        display: flex;
    }
    .timeTabs{
        margin-right: 20px;
        display: flex;
        div{
            height: 40px;
            color: #BABEC7;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: solid 1px #BABEC7;
            border-bottom: solid 1px #BABEC7;
            border-right: solid 1px #BABEC7;
            cursor: pointer;
        }
    }
    .borderLeft{
        border-left: solid 1px #BABEC7;
    }
    .active{
        background-color: #3E3F54;
    }
    .widtha{
        width: 80px;
    }
    .widthb{
        width: 100px;
    }
</style>